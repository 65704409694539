<template>
  <div class="sld_login" @click="choose_lan = false">
    <div class="sld_login_header">
      <div class="content">
        <router-link tag="a" class="l_logo" :to="`/index`">
          <img
            class="img"
            :src="configInfo.main_site_logo"
            :onerror="defaultImg"
            alt
          />
        </router-link>
        <div class="flex_row_between_center" style="flex: 1;">
          <div class="r_register_wrap">
            {{ L['想起密码？'] }}
            <a
              href="javascript:void(0)"
              class="go_register_btn"
              @click="goToPage('/login')"
            >
              {{ L['去登录'] }}
            </a>
          </div>
          <div class="lanBox" @click.stop="choose_lan = !choose_lan">
            <img class="lan_flag" :src="curFlag" alt="">
            <span class="lan_name">{{curLang}}</span>
            <img class="lan_down" src="@/assets/downarrow.png" alt="">
            <div class="lan_con_box" v-if="choose_lan">
              <div class="lan_con_item" @click.stop="changeLanguage(item.languageTag)" v-for="(item,index) in lanList" :key="index">{{ item.languageName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sld_login_content">
      <img
        class="bg"
        :src="configInfo.main_user_forget_password_bg"
        :onerror="defaultBgImg"
        alt
      />
      <div class="login">
         
        <div class="login_box">
          <div class="top">
            <div class="item1">{{ L['找回密码'] }}</div>
          </div>
           <div class="top">
              <div :class="{ item: true, active: loginType == 1 }" @click="changeLoginType(1)">
                {{ L['手机号找回'] }}
              </div>
              <div :class="{ item: true, active: loginType == 2 }" @click="changeLoginType(2)">
                {{ L['邮箱找回'] }}
              </div>
            </div>
          <div class="center" v-if="loginType == 1">
            <div class="item account">
              <!-- <span
                style="color: #BBB;font-size: 21px;padding-top: 7px"
                class="icon iconfont icon-shouji2"
              ></span> -->
              <el-select class="voice_select" v-model="code"
                  @change="change" :placeholder="L['请选择']" v-if="codelist.data.length">
                  <el-option  v-for="(item, index) in codelist.data" :key="index" :value="item.countryTelCode">{{
                    item.codeText
                  }}</el-option>
                  
                </el-select>
                  <input type="number" v-model="name" :placeholder="L['请输入手机号']" class="input" autocomplete="off" style="width:220px;padding:0 40px 0 10px"  />
                  <div data-type="userName" class="cancel" @click="clearInputVal('name')">
                    <span style="color: #bbb;" class="iconfont icon-cuowu"></span>
                  </div>
              <!-- <input
                type="number"
                v-model="name"
                :placeholder="L['请输入手机号']"
                class="input"
              />
              <div
                data-type="userName"
                class="cancel"
                @click="clearInputVal('name')"
              >
                <span style="color: #bbb;" class=" iconfont icon-cuowu"></span>
              </div> -->
            </div>
            <div class="item password">
              <span
                style="color: #BBB;font-size: 18px;padding-top: 8px"
                class="icon iconfont icon-yanzhengma2"
              ></span>
              <input
                type="number"
                v-model="smsCode"
                :placeholder="L['请输入验证码']"
                class="input"
              />
              <a
                href="javascript:void(0);"
                class="send_code"
                @click="getSmsCode"
                >{{
                  countDownM ? countDownM + L['s后获取'] : L['获取验证码']
                }}</a
              >
            </div>
            <div class="item password">
              <span
                style="color: #BBB;font-size: 21px;padding-top: 7px"
                class="icon iconfont icon-mima1"
              ></span>
              <input
                :type="showPwdFlag ? 'text' : 'password'"
                v-model="password"
                :placeholder="L['请输入6～20位英文、数字、符号']"
                class="input"
              />
              <div class="cancel" @click="isShowPwd">
                <span
                  :style="{
                    color: '#bbb',
                    fontSize: showPwdFlag ? '20px' : '16px'
                  }"
                  :class="{
                    iconfont: true,
                    'icon-bukejian11': !showPwdFlag,
                    'icon-kejian': showPwdFlag,
                    show_pwd: showPwdFlag
                  }"
                ></span>
              </div>
            </div>
            <div class="error">
              <span
                v-if="errorMsg"
                style="color: #e1251b;font-size: 14px"
                class="iconfont icon-jubao"
              ></span>
              {{ errorMsg }}
            </div>
            <a href="javascript:void(0)" @click="login" class="login_btn">{{
              L['找回密码']
            }}</a>
          </div>
          <div class="center" v-if="loginType == 2">
            <div class="item account">
              <span
                style="color: #BBB;font-size: 21px;padding-top: 7px"
                class="icon iconfont icon-youxiang"
              ></span>
              <!-- <el-select class="voice_select" v-model="code"
                  @change="change" :placeholder="L['请选择']" v-if="codelist.data.length">
                  <el-option  v-for="(item, index) in codelist.data" :key="index" :value="item.countryTelCode">{{
                    item.countryTelCode
                  }}</el-option>
                  
                </el-select>
                  <input type="number" v-model="name" :placeholder="L['请输入手机号']" class="input" autocomplete="off" style="width:220px;padding:0 40px 0 10px"  />
                  <div data-type="userName" class="cancel" @click="clearInputVal('name')">
                    <span style="color: #bbb;" class="iconfont icon-cuowu"></span>
                  </div> -->
              <input
                type="text"
                v-model="name"
                :placeholder="L['请输入邮箱']"
                class="input"
              />
              <div
                data-type="userName"
                class="cancel"
                @click="clearInputVal('name')"
              >
                <span style="color: #bbb;" class=" iconfont icon-cuowu"></span>
              </div>
            </div>
            <div class="item password">
              <span
                style="color: #BBB;font-size: 18px;padding-top: 8px"
                class="icon iconfont icon-yanzhengma2"
              ></span>
              <input
                type="number"
                v-model="smsCode"
                :placeholder="L['请输入验证码']"
                class="input"
              />
              <a
                href="javascript:void(0);"
                class="send_code"
                @click="getSmsCode"
                >{{
                  countDownM ? countDownM + L['s后获取'] : L['获取验证码']
                }}</a
              >
            </div>
            <div class="item password">
              <span
                style="color: #BBB;font-size: 21px;padding-top: 7px"
                class="icon iconfont icon-mima1"
              ></span>
              <input
                :type="showPwdFlag ? 'text' : 'password'"
                v-model="password"
                :placeholder="L['请输入6～20位英文、数字、符号']"
                class="input"
              />
              <div class="cancel" @click="isShowPwd">
                <span
                  :style="{
                    color: '#bbb',
                    fontSize: showPwdFlag ? '20px' : '16px'
                  }"
                  :class="{
                    iconfont: true,
                    'icon-bukejian11': !showPwdFlag,
                    'icon-kejian': showPwdFlag,
                    show_pwd: showPwdFlag
                  }"
                ></span>
              </div>
            </div>
            <div class="error">
              <span
                v-if="errorMsg"
                style="color: #e1251b;font-size: 14px"
                class="iconfont icon-jubao"
              ></span>
              {{ errorMsg }}
            </div>
            <a href="javascript:void(0)" @click="login" class="login_btn">{{
              L['找回密码']
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { ref, getCurrentInstance, onMounted, watch ,reactive} from 'vue'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
export default {
  name: 'ForgetPassword',
  setup() {
    const name = ref('') //用户名
    const store = useStore()
    const errorMsg = ref() //错误提示
    const password = ref('') //密码
    const smsCode = ref('') //短信验证码
    const showPwdFlag = ref(false) //密码是否明文显示，默认密文
    const router = useRouter()
    const { proxy } = getCurrentInstance()
    const L = proxy.$getCurLanguage()
    const clickSmscode = ref(false) //是否获取验证码中
    const countDownM = ref(0) //短信验证码倒计时
    const timeOutId = ref('') //定时器的返回值
    const codelist = reactive({data: []})
    const code = ref('') //区号
    const loginType = ref(1) //找回方式：1-手机验证码，2-邮箱验证码
    const configInfo = ref(store.state.configInfo)
    const choose_lan = ref(false)
    const lanList = ref([])
    const curLang = ref(localStorage.getItem('curLang') == 'en' ? 'English' : '简体中文')
    const curFlag =  ref(localStorage.getItem('curLang') == 'en' ? require('@/assets/flag/English.png') : require('@/assets/flag/China.png'))
    const langTotalList = localStorage.getItem('langTotalList') ? JSON.parse(localStorage.getItem('langTotalList')) : [];
    const changeLanguage = (lang) => {
      if(lang == 'zh' || lang == 'en'){
        localStorage.setItem('curLang',lang);
        window.location.reload();
      } else {
        curLang.value = lanList.value.find(item => item.languageTag == lang).languageName;
        curFlag.value = lanList.value.find(item => item.languageTag == lang).img;
        choose_lan.value = false
      }
    }
  
    const defaultImg = ref(
      'this.src="' + require('../../../assets/common_top_logo.png') + '"'
    )
    const defaultBgImg = ref(
      'this.src="' + require('../../../assets/forget_pwd_bg.png') + '"'
    )
    //登录方式切换
    const changeLoginType = type => {
      loginType.value = type
      name.value = ''
      password.value = ''
      errorMsg.value = ''
    }
     const change = (e) => {
      console.log(e)
    }
    
     const getCode = () => {
         proxy
        .$get('v3/Language/common/countryCodeList')
        .then(res => {
          if (res.state == 200) {
            codelist.data = res.data
          }
        })
    }
    const login = () => {
      let param = {}
      param.type = loginType.value
      param.memberMobile = name.value
      param.loginPwd = password.value
      param.verifyCode = smsCode.value
      if(loginType.value == 1){
        param.countryTelCode = code.value
        if(!param.countryTelCode){
            errorMsg.value = L['请选择区号']
            return
      }
       //手机号验证
      if (!param.memberMobile) {
            errorMsg.value = L['请输入手机号']
            return false
            } else {
            let checkMobile = proxy.$checkPhone(param.memberMobile)
            if (checkMobile !== true) {
                errorMsg.value = checkMobile
                return false
            }
            }
      } else {
        if (!param.memberMobile) {
            errorMsg.value = L['请输入邮箱']
            return false
        } else {
            let checkEmail = proxy.$checkEmail(param.memberMobile)
            if (checkEmail !== true) {
                errorMsg.value = checkEmail
                return false
            }
        }
      }
     

      //验证码校验
      if (!param.verifyCode) {
        errorMsg.value = L['请输入验证码']
        return false
      } else {
        let checkSmsCode = proxy.$checkSmsCode(param.verifyCode)
        if (checkSmsCode !== true) {
          errorMsg.value = checkSmsCode
          return false
        }
      }

      //密码校验
      if (!param.loginPwd) {
        errorMsg.value = L['请输入密码']
        return false
      } else {
        let checkPwd = proxy.$checkPwd(param.loginPwd)
        if (checkPwd !== true) {
          errorMsg.value = checkPwd
          return false
        }
      }

      proxy
        .$post('v3/member/front/memberPassword/resetLoginPwd', param)
        .then(res => {
          if (res.state == 200) {
            //成功提示，并返回到登录页面
            ElMessage.success(res.msg)
            setTimeout(() => {
              router.back()
            }, 500)
          } else {
            //提示错误
            errorMsg.value = res.msg
          }
        })
    }
    //清空输入框内容
    const clearInputVal = type => {
      if (type == 'name') {
        name.value = ''
      } else if (type == 'password') {
        password.value = ''
      }
    }
    //密码是否显示
    const isShowPwd = () => {
      showPwdFlag.value = !showPwdFlag.value
    }

    watch([name, smsCode, password], () => {
      password.value = password.value.substring(0, 20)
      smsCode.value = smsCode.value.substring(0, 6)
    //   name.value = name.value.substring(0, 11)
    })

    //获取短信验证码
    const getSmsCode = () => {
      if (clickSmscode.value) {
        return
      } else if (countDownM.value) {
        return
      }
      if(loginType.value == 1){
        if(!code.value){
            errorMsg.value = L['请选择区号']
            return
        }
       //手机号验证
      if (!name.value) {
            errorMsg.value = L['请输入手机号']
            return false
            } else {
            let checkMobile = proxy.$checkPhone(name.value)
            if (checkMobile !== true) {
                errorMsg.value = checkMobile
                return false
            }
            }
      } else {
        if (!name.value) {
            errorMsg.value = L['请输入邮箱']
            return false
        } else {
            let checkEmail = proxy.$checkEmail(name.value)
            if (checkEmail !== true) {
                errorMsg.value = checkEmail
                return false
            }
        }
      }

      clickSmscode.value = true
        let param = {}
        param.mobile = name.value
         if(loginType.value == 1){
             param.countryCode = code.value
         }
       
        param.type = 'free'
        proxy.$get('v3/msg/front/commons/smsCode', param).then(res => {
          if (res.state == 200) {
            errorMsg.value = ''
            countDownM.value = 60
            countDown()
            clickSmscode.value = false
          } else {
            //提示错误
            errorMsg.value = res.msg
            clickSmscode.value = false
          }
        })
    }
    //倒计时
    const countDown = () => {
      countDownM.value--
      if (countDownM.value == 0) {
        clearTimeout(timeOutId.value)
      } else {
        timeOutId.value = setTimeout(countDown, 1000)
      }
    }
    //通过replace方式跳转页面
    const goToPage = type => {
      router.replace({
        path: type
      })
    }
    onMounted(() => {
      getCode()
      document.body.classList.remove('el-popup-parent--hidden')
      proxy
        .$get('v3/Language/common/list')
            .then(res => {
                if (res.state == 200) {
                    const langResList = res.data;
                    langResList.forEach(lang =>{
                      const tag = lang.languageTag;
                      const localItem = langTotalList.find(item => item.val == tag)
                      if(localItem) lang.img = localItem.img;
                    })
                    lanList.value = langResList;
                }
            })
    })
    return {
      curFlag,
      curLang,
      changeLanguage,
      choose_lan,
      lanList,
      L,
      showPwdFlag,
      login,
      name,
      password,
      clearInputVal,
      errorMsg,
      isShowPwd,
      getSmsCode,
      clickSmscode,
      countDownM,
      smsCode,
      goToPage,
      configInfo,
      defaultImg,
      defaultBgImg,
      codelist,
      code,
      change,
      getCode,
      changeLoginType,
      loginType,
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../style/forgetPassword.scss';
.voice_select{
  ::v-deep .el-input__inner{
      color: #415058;
      border-color: #e8e8e8 !important;
      outline: none;
      &::placeholder{
      color: #BEBEBE;
    }
  }
}
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
