// 消息接收设置
<template>
  <div class="sld_setting">
    <div class="sld_setting_title">{{ L['接收设置'] }}</div>
      <div class="setting_list">
      <div
        class="setting_pre"
        
      >
        <div class="list_title">{{L['接收语言：']}}</div>
        <div class="list">
          <div
            class="new_list_pre" 
          >
            <p class="list_pre_title">{{L['接收语言：']}}</p>
            <div>
                <select name="language" id="language" v-model="language" @change="change">
                    <option v-for="(item, index) in LanguageList.data" :key="index">{{
                        item.languageName
                    }}</option>
                </select>
                <div class="new_zhushi">{{L['选择接收语言后，消息通知将以该种语言推送']}}</div>
            </div>
            
          </div>
        </div>
        
      </div>
    </div>
    <div class="setting_list">
      <div
        class="setting_pre"
        v-for="(settingItem, settingIndex) in settingList.data"
        :key="settingIndex"
      >
        <div class="list_title">{{ settingItem.tplName }}</div>
        <div class="list">
          <div
            class="list_pre"
            v-for="(preItem, preIndex) in settingItem.memberTplList"
            :key="preIndex"
          >
            <p class="list_pre_title">{{ preItem.tplName }}</p>
            <div class="list_pre_option flex_row_center_center">
              <span
                class="receive"
                :class="{ active: preItem.isReceive }"
                @click="modifySetting(preItem.tplCode, preItem.isReceive)"
                >{{ L['接收'] }}</span
              >
              <span
                class="receive"
                :class="{ no_receive: !preItem.isReceive }"
                @click="modifySetting(preItem.tplCode, preItem.isReceive)"
                >{{ L['不接收'] }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, getCurrentInstance, onMounted ,ref} from 'vue'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
export default {
  setup() {
    const { proxy } = getCurrentInstance()
    const L = proxy.$getCurLanguage()
    const store = useStore()
    const settingList = reactive({ data: {} })
    const memberInfo = reactive({ data: {} })
    const LanguageList = reactive({ data: [] })
    const language = ref('')
    
    onMounted(() => {
      getInitInfo()
      getMesList()
      getLanguageList()
    })
    //获取消息设置列表
    const getMesList = () => {
      proxy.$get('v3/msg/front/msg/setting/list').then(res => {
        if (res.state == 200) {
          settingList.data = res.data
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
     const change = (e) => {
         let new_name;
         LanguageList.data.map((item,index)=>{
             if(item.languageName == language.value){
                 new_name = item.languageTag
             }
         })
      let params = {
          currentLanguage:new_name
      }

      proxy.$post('v3/member/front/member/updateInfo', params).then(res => {
        if (res.state == 200) {
          ElMessage.success(res.msg)
          getInitInfo()
        } else {
          ElMessage.warning(res.msg)
        }
      })
    }
    const getInitInfo = () => {
      //获取会员信息数据
      proxy.$get('v3/member/front/member/getInfo').then(res => {
        if (res.state == 200) {
          memberInfo.data = res.data
          store.commit('updateMemberInfo', memberInfo.data) //将购物车数据存储到vuex的store中
        }
      })
    }
     //获取语言列表列表
    const getLanguageList = () => {
      proxy.$get('v3/Language/common/list').then(res => {
        if (res.state == 200) {
          LanguageList.data = res.data
          let languageName = store.state.memberInfo.currentLanguage
           LanguageList.data.map((item,index)=>{
             if(item.languageTag == languageName){
                 language.value = item.languageName
             }
         })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    //接收设置开关修改
    const modifySetting = (tplCode, isReceive) => {
      let params = {
        tplCode,
        isReceive: isReceive == 0 ? 1 : 0
      }
      proxy.$post('v3/msg/front/msg/setting/isReceive', params).then(res => {
        if (res.state == 200) {
          getMesList()
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    return {
      settingList,
      LanguageList,
      getLanguageList,
      modifySetting,
      language,
      L,
      getInitInfo,
      change,
      memberInfo
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/base.scss';
@import '../../style/msgSetting.scss';
</style>
