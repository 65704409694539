<template>
  <section v-if="recommendList.length">
    <div class="recommend_layout" :class="[layoutFrom]" v-if="layoutFrom !== 'HomeIndex'">
      <div class="recommend_goods_wrap">
        <header class="recommend_header" :class="[layoutFrom]" v-if="recommendTitle">
          <span>{{ recommendTitle }}</span>
        </header>
        <section class="recommend_line flex_row_start_center">
          <div class="arrow">
            <img src="@/assets/recommend/arrow_left.png" alt="" @click="moveScroll('prev')"
              :class="{ disabledBtn: disabledState.left_btn }">
          </div>
          <div class="recommend_container" ref="recommEl">
            <div class="flex_row_start_center"
              :style="{ transform: translateCompute, transition: 'all .3s', transformOrigin: 'left' }">
              <div class="flex_row_center_center" v-for="(outs, outDx) in recommendList" :key="outDx"
                :style="{ minWidth: uniWidth + 'px' }">
                <div class="goods_unit" v-for="(inner, index) in outs" :key="index">
                  <router-link class="flex_column_center_center"
                    :to="`/goods/detail?productId=${inner.defaultProductId}&sourcePath=${route.path}`" target="_blank">
                    <img :src="inner.goodsImage" alt="" :class="[layoutType, layoutFrom]">
                    <div style="margin-top: 15px;">
                      <div class="goods_n" :class="[layoutType]">{{ inner.goodsName }}</div>
                      <div class="flex_row_between_center" style="margin-top: 17px;">
                        <span class="price_n">{{ L['￥'] }}{{ $getPartNumber(inner.goodsPrice, 'all') }}</span>
                        <span class="sale_n">已售{{ $formatW(inner.saleNum) }}</span>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div class="goods_unit" v-for="(left, leftIdx) in getLeftOuts(outs.length)" :key="leftIdx">
                  <div class="left_space" style="width: 236px;"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="arrow">
            <img src="@/assets/recommend/arrow_right.png" alt="" @click="moveScroll('next')"
              :class="{ disabledBtn: disabledState.right_btn }">
          </div>
        </section>
      </div>
    </div>

    <div class="recommend_index_layout" v-else>
      <header class="recommend_index_header flex_row_center_center" v-if="recommendTitle">
        <img src="@/assets/recommend/recom_left.png" alt="">
        <span>{{ recommendTitle }}</span>
        <img src="@/assets/recommend/recom_right.png" alt="">
      </header>
      <section class="recommend_index_line">
        <div class="flex_row_center_center recommend_index_wrap">
          <div class="goods_unit_index" v-for="(inner, outDx) in recommendList" :key="outDx">
            <router-link class="flex_column_center_center" :to="`/goods/detail?productId=${inner.defaultProductId}`"
              target="_blank">
              <img :src="inner.goodsImage">
              <div style="margin-top: 15px;">
                <div class="goods_n_index">{{ inner.goodsName }}</div>
                <div class="price_n_index">{{ L['￥'] }}{{ $getPartNumber(inner.goodsPrice, 'all') }}</div>
              </div>
            </router-link>
          </div>

          <div class="goods_unit_index_space" v-for="(item, index) in lengthLeft5" :key="index">
            <div style="width: 190px;"></div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { useRoute } from 'vue-router'
import { watch, ref, getCurrentInstance, computed, onUpdated } from 'vue'
export default {
  props: {
    goodsId: Number,
    paySn: Number,
    orderSn: Number
  },
  setup (props) {
    const route = useRoute()
    const layoutType = ref('normal')
    const layoutFrom = ref('')
    const { proxy } = getCurrentInstance()
    const recommendTitle = ref('')
    const L = proxy.$getCurLanguage()
    const recommendList = ref([])
    const translateNum = ref(0)
    const translateCompute = computed(() => (`translateX(${translateNum.value}px)`))
    let moveIndex = ref(0)
    const uniWidth = ref(0)
    const lengthLeft5 = computed(() => {
      return (10 - recommendList.value.length) % 5
    })


    const pageMapping = {
      "HomeIndex": {
        name: 'recommend_position_index',
        code: 'index',
        disabledShow: false
      },
      'GoodsDetail': {
        name: 'recommend_position_goods',
        code: 'goods',
        disabledShow: false
      },
      'CartIndex': {
        name: 'recommend_position_cart',
        code: 'cart',
        disabledShow: true
      },
      "memberOrderList": {
        name: 'recommend_position_order_list',
        code: 'order_list',
        disabledShow: true
      },
      'memberOrderDetail': {
        name: 'recommend_position_order_detail',
        code: 'order_detail',
        disabledShow: true
      },
      'MemberCollectGoods': {
        name: 'recommend_position_collect',
        code: 'collect',
        disabledShow: true
      },
      'MemberCollectStore': {
        name: 'recommend_position_follow',
        code: 'follow',
        disabledShow: true
      },
      'MemberIndex': {
        name: 'recommend_position_member',
        code: 'member',
        disabledShow: true
      },
      'MemberFootprint': {
        name: 'recommend_position_look',
        code: 'look',
        disabledShow: true
      }
    }

    let currentPageName = ''
    let isSmartEnble = false
    const intelliRecomSetting = () => {
      proxy.$get('v3/system/front/setting/getSettings', {
        names: pageMapping[currentPageName].name
      }).then(res => {
        if (res.state == 200) {
          let [result] = res.data
          if (result) {
            let parsedRes = JSON.parse(result)
            isSmartEnble = parsedRes.enable
            if (parsedRes.enable) {
              recommendTitle.value = parsedRes.recommend_title
              getRecommendList()
            } else {
              if (pageMapping[currentPageName].disabledShow) {
                recommendTitle.value = L['猜你喜欢']
                getRecommendList();//获取推荐商品数据
              } else {
                recommendList.value = []
              }
            }
          }
        }
      })
    }

    const getLeftOuts = (length) => {
      return (4 - length)
    }

    const getRecommendList = () => {
      recommendList.value = []
      moveIndex.value = 0
      translateNum.value = 0
      let { name } = route
      let param = {}, url = '';
      if (isSmartEnble) {
        url = 'v3/goods/front/goodsRecommend/recommendList';
        param.positionCode = pageMapping[currentPageName].code;
        if (props.paySn) {
          param.paySn = props.paySn;
        }
        if (props.orderSn) {
          param.orderSn = props.orderSn;
        }
      } else {
        url = 'v3/goods/front/goods/recommendList';
        param.queryType = 'cart';
      }

      param.pageSize = currentPageName == 'HomeIndex' ? 10 : 100;
      param.current = 1;
      if (name == 'GoodsDetail') {
        param.goodsId = props.goodsId;
      }
      proxy.$get(url, param).then(res => {
        if (res.state == 200) {
          if (currentPageName !== 'HomeIndex') {
            let startX = 0
            let dataList = res.data.list
            while (startX <= dataList?.length) {
              let tmpList = dataList.slice(startX, startX += 4)
              if (tmpList.length) {
                recommendList.value.push(tmpList)
              }
            }
          } else {
            recommendList.value = res.data.list
          }
        } else {
          //错误提示
        }
      })
    }


    const debounceFnWrap = (fn, delay) => {
      let timer = null
      let execCount = 0
      return function (...args) {
        clearTimeout(timer)
        timer = setTimeout(() => {
          if (execCount >= 1) {
            fn(args)
          }
          execCount = 0
        }, delay)
        execCount++
      }
    }

    const debounceWatch = debounceFnWrap(() => {
      layoutFrom.value = route.name
      currentPageName = route.name
      if (route.params?.type == 'store') {
        currentPageName = 'MemberCollectStore'
      } else if (route.params?.type == 'goods') {
        currentPageName = 'MemberCollectGoods'
      }
      if (pageMapping[currentPageName]) {
        intelliRecomSetting()
      } else {
        recommendList.value = []
      }
    }, 200)

    watch([() => route.name, () => route.params], debounceWatch, { immediate: true })
    watch(props, debounceWatch, { immediate: true })

    const disabledState = computed(() => {
      let left_btn = (moveIndex.value == 0)
      let right_btn = (moveIndex.value == recommendList.value.length - 1)
      return {
        left_btn,
        right_btn
      }
    })
    const moveScroll = debounceFnWrap((type) => {
      const moveDistance = proxy.$refs.recommEl.clientWidth
      let targetDis = moveDistance
      if (type == 'prev' && moveIndex.value > 0) {
        moveIndex.value--
        translateNum.value += targetDis
      } else if (type == 'next' && moveIndex.value < recommendList.value.length - 1) {
        moveIndex.value++
        translateNum.value -= targetDis
      }
    }, 300)

    onUpdated((e) => {
      if (proxy.$refs.recommEl && !uniWidth.value) {
        uniWidth.value = proxy.$refs.recommEl.clientWidth
      }
    })


    return {
      uniWidth,
      route,
      disabledState,
      moveIndex,
      layoutType,
      layoutFrom,
      recommendList,
      translateCompute,
      moveScroll,
      recommendTitle,
      lengthLeft5,
      getLeftOuts
    }
  }
}
</script>

<style lang="scss" scoped>
.recommend_layout {
  &.GoodsDetail {
    margin: 31px auto 31px;
    border: 1px solid #DFDFDF;
  }

  &.memberOrderDetail,
  &.CartIndex {
    margin-top: 15px;
    border: 1px solid #DFDFDF;
    margin-bottom: 20px;
  }

  &.CartIndex {
    margin-top: 60px;
    margin-bottom: 60px;
  }

}

.recommend_index_layout {

  .recommend_index_header {
    margin-top: 19px;

    img {
      width: 40px;
      height: 29px;
    }

    span {
      margin: 0 16px;
      display: inline-block;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
  }

  .recommend_index_line {
    margin-top: 29px;
    background: #fff;

    .recommend_index_wrap {
      flex-wrap: wrap;

      .goods_unit_index_space {
        padding: 10px 25px;
      }

      .goods_unit_index {
        padding: 25px;

        img {
          width: 190px;
          height: 190px;
        }

        .goods_n_index {
          width: 180px;
          height: 39px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          word-break: break-all;
          overflow: hidden;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          text-align: center;
        }

        .price_n_index {
          margin-top: 15px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 600;
          color: var(--color_price);
          text-align: center;
        }
      }
    }
  }
}

.disabledBtn {
  opacity: 0.3;
  pointer-events: none;
}

.recommend_goods_wrap {
  width: 100%;
  background: #fff;

  .recommend_header {
    height: 51px;
    padding: 0 21px;
    border-bottom: 1px solid #E8E8F1;
    line-height: 51px;

    &.GoodsDetail,
    &.memberOrderDetail,
    &.CartIndex {
      background: #F8F8F8;
      border-bottom: none !important;

      span {
        font-weight: normal;
      }
    }

    &.MemberFootprint {
      margin-top: 15px;
    }

    span {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
    }
  }

  .recommend_line {
    padding: 20px;

    .arrow {
      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
        transition: all .3s;
        transform-origin: center;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .recommend_container {
      flex: 1;
      overflow-x: hidden;
      margin: 0 16px;
    }
  }

  .goods_unit {
    padding: 10px;
    cursor: pointer;
    margin: 0 5px;

    img.normal {
      width: 239px;
      height: 239px;
      object-fit: cover;
      background: #f7f7f7;
    }

    img.GoodsDetail,
    img.CartIndex,
    img.memberOrderDetail {
      width: 236px;
      height: 236px;
    }

    img.small {
      width: 195px;
      height: 195px;
      object-fit: contain;
      background: #777777;
    }

    .goods_n {
      height: 39px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      word-break: break-all;
      overflow: hidden;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
    }

    .goods_n.normal {
      font-size: 14px;
      width: 235px;
    }

    .goods_n.small {
      width: 190px;
      font-size: 13px;

    }

    .price_n {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: var(--color_price);
    }

    .sale_n {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #777777;
    }
  }
}
</style>